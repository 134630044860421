<template>
  <b-row v-if="partenaire != false && params != false">
    <b-col md="10" offset-md="1">
      <div class="p-5">
        <p class="h5 text-dark">Partenaire: {{partenaire.id}} - {{partenaire.raison_social}}  <b-button :variant="statusA.color" style="margin-left:15rem">{{statusA.titre}}</b-button></p>
        <b-card no-body>
          <form ref="form">
            <b-tabs card>
              <template #tabs-end>
                <b-dropdown class="ms-3 mb-2">
                  <template #button-content>
                    <i class="fas fa-thermometer-half"></i>
                  </template>
                  <b-dropdown-item v-for="(s,i) in params.PartenaireStatus" :key="i" :active="s.id === partenaire.status_id" @click="partenaire.status_id = s.id">{{s.titre}}</b-dropdown-item>
                </b-dropdown>
                <b-button variant="success" class="text-white ms-1 mb-2" @click="save()">
                  <span v-if="save_loader === false">Sauvegarder</span>
                  <b-spinner v-else type="grow" small></b-spinner>
                </b-button>
                <b-button v-if="partenaire.id != null && partenaire.status_id === 1" variant="primary" class="text-white ms-1 mb-2" @click="activation()">
                  <span>Activation</span>
                </b-button>
              </template>
              <b-tab title="Information generale" active>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Raison sociale:">
                      <b-form-input v-model="partenaire.raison_social" required></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Representant:">
                      <b-form-input v-model="partenaire.representant" required></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Adresse:">
                      <b-form-input v-model="partenaire.adresse" required></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Code postal:">
                      <b-form-input v-model="partenaire.cp" required></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Ville:">
                      <b-form-input v-model="partenaire.ville" required></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="SIREN:">
                      <b-form-input v-model="partenaire.siren" required></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="ORIAS:">
                      <b-form-input v-model="partenaire.orias" required></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Forme juridique:">
                      <select class="form-control" v-model="partenaire.forme_juridique" required>
                        <option value="EIRL">EIRL</option>
                        <option value="EURL">EURL</option>
                        <option value="SASU">SASU</option>
                        <option value="SAS">SAS</option>
                        <option value="SARL">SARL</option>
                        <option value="SA">SA</option>
                        <option value="SNC">SNC</option>
                      </select>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Capital:">
                      <b-form-input v-model="partenaire.capital" required></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Nom du contact:">
                      <b-form-input v-model="user.nom" required></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Prenom du contact:">
                      <b-form-input v-model="user.prenom" required></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Email du contact:">
                      <b-form-input v-model="user.email" type="email" required></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Telephone du contact:">
                      <b-form-input v-model="user.telephone" required></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Apporteur d'affaire:">
                      <treeselect :options="params.Apporteur" v-model="partenaire.apporteur_id" :matchKeys="['raison_social']" required>
                        <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName }" :class="labelClassName">
                          {{node.raw.raison_social}}
                        </label>
                        <div slot="value-label" slot-scope="{ node }">{{node.raw.raison_social}}</div>
                      </treeselect>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Convention:">
                      <b-input-group class="mt-3">
                        <input type="file" placeholder="Choisissez votre document" @change="handleFile" class="form-control"/>
                        <b-input-group-append>
                          <b-button variant="primary" v-if="partenaire.convention != null" :href="partenaire.convention" target="_blank"><i class="fas fa-eye"></i></b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Option immo soumission manuelle:">
                      <select class="form-control" v-model="partenaire.soumission_manuelle">
                        <option :value="true">Oui</option>
                        <option :value="false">Non</option>
                      </select>
                    </b-form-group>
                  </b-col>
                  <b-col md="6" v-if="partenaire.soumission_manuelle === true">
                    <b-form-group label="Auto remplissage agence :">
                      <select class="form-control" v-model="partenaire.auto_agence">
                        <option :value="true">Oui</option>
                        <option :value="false">Non</option>
                      </select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="partenaire.auto_agence === true">
                  <b-col md="6">
                    <b-form-group label="Email automatique">
                      <b-form-input v-model="partenaire.auto_email" required></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Telephone automatique">
                      <b-form-input v-model="partenaire.auto_telephone" required></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Type de commission:">
                      <select class="form-control" v-model="partenaire.type_commission" required>
                        <option v-for="(c, i) in params.CommissionType" :value="c.id" :key="i"> {{c.titre}}</option>
                      </select>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Commission Fixe:">
                      <b-input-group append="€">
                        <b-form-input v-model.number="partenaire.type_commission_fixe" type="number" required></b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Commission Lineaire:" v-if="partenaire.type_commission === 2">
                      <b-input-group append="%">
                        <b-form-input v-model.number="partenaire.type_commission_lineaire" type="number" required></b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab title="Commentaire" v-if="ifNew === false">
                <b-form-textarea v-model="partenaire.commentaire" rows="7" disabled id="commentaire_input"></b-form-textarea>
                <b-form-group label="Votre commentaire:">
                  <b-input-group>
                    <b-form-input v-model="commentaireTmp" @keyup.enter="addCommentaire()"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="success" @click="addCommentaire()">
                        <i class="fas fa-paper-plane"></i>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-tab>
              <b-tab title="Facturation" v-if="ifNew === false">
                <b-table-simple bordered hover small caption-top responsive>
                  <b-thead head-variant="dark">
                    <b-tr>
                      <b-th>Periode mensuelle</b-th>
                      <b-th>Nombre d'inscription</b-th>
                      <b-th>Nombre de souscription</b-th>
                      <b-th>Montant a payer</b-th>
                      <b-th>Date de paiement</b-th>
                      <b-th>Statut paiement</b-th>
                      <b-th>Documents</b-th>
                      <b-th>Action</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody class="text-center">
                    <b-tr v-for="(f,i) in factures" :key="i">
                      <b-td>{{formatDate(f.debut)}} - {{formatDate(f.fin)}}</b-td>
                      <b-td>{{f.nb_inscrit}}</b-td>
                      <b-td>{{f.nb_souscription}}</b-td>
                      <b-td>{{f.montant_ht.toFixed(2)}}</b-td>
                      <b-td><b-form-input type="date" v-model="f.date_paiement"></b-form-input></b-td>
                      <b-td>
                        <b-button v-if="f.status_id === 1" :variant="$store.api.getParam(params.SouscriptionFactureStatus, f.status_id).color">{{$store.api.getParam(params.SouscriptionFactureStatus, f.status_id).titre}}</b-button>
                        <b-dropdown v-else class="lang-dropdown" :variant="$store.api.getParam(params.SouscriptionFactureStatus, f.status_id).color">
                          <template #button-content>
                            {{$store.api.getParam(params.SouscriptionFactureStatus, f.status_id).titre}}
                          </template>
                          <b-dropdown-item v-for="(s, i) in params.SouscriptionFactureStatus" :key="i" @click="f.status_id = s.id" :disabled="s.id === 1">{{s.titre}}</b-dropdown-item>
                        </b-dropdown>
                      </b-td>
                      <b-td>
                        <b-button v-if="f.docs.preuve === null" size="sm" class="ms-1 text-white" variant="warning" @click="$refs.uploadDoc.show(); doc_id = f.id; doc_key = 'preuve'" v-b-tooltip.hover title="Preuve de virement">P</b-button>
                        <b-button v-else size="sm" class="ms-1 text-white" variant="primary" :href="f.docs.preuve" target="_blank" v-b-tooltip.hover title="Preuve de virement">P</b-button>
                        
                        <b-button v-if="f.docs.facture === null" size="sm" class="ms-1 text-white" variant="warning" @click="$refs.uploadDoc.show(); doc_id = f.id; doc_key = 'facture'" v-b-tooltip.hover title="Facture partenaire">F</b-button>
                        <b-button v-else size="sm" class="ms-1 text-white" variant="primary" :href="f.docs.facture" target="_blank" @click="$refs.uploadDoc.show(); doc_id = f.id; doc_key = 'facture'" v-b-tooltip.hover title="Facture partenaire">F</b-button>                                                
                      </b-td>
                      <b-td><b-button v-if="f.status_id != 1" @click="saveFac(f)" variant="success" size="sm"><i class="fas fa-save"></i></b-button></b-td>
                    </b-tr>
                  </b-tbody>
                  <b-tfoot class="text-center">
                    <b-tr>
                      <b-th></b-th>
                      <b-th>{{total_inscrit}}</b-th>
                      <b-th>{{total_souscriptions}}</b-th>
                      <b-th>{{total_ht}}</b-th>
                      <b-th></b-th>
                      <b-th></b-th>
                      <b-th></b-th>
                    </b-tr>
                  </b-tfoot>
                </b-table-simple>
              </b-tab>
              <b-tab title="Commissions" v-if="ifNew === false">
                <b-table-simple stacked="md" striped hover>
                  <b-thead>
                    <b-tr>
                      <b-th>Date</b-th>
                      <b-th>Type</b-th>
                      <b-th>Souscription</b-th>
                      <b-th>Montant HT</b-th>                      
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr v-for="(c, i) in commissions" :key="i" :variant="c.montant_ht < 0 ? 'danger' : ''">                      
                      <b-td>{{$store.api.timestampToDate(c.date_create, true)}}</b-td>
                      <b-td>{{c.commission_type}}</b-td>
                      <b-td><a :href="`/#/admin/inscrit/${c.souscription_id}`">{{c.souscription_id}}</a></b-td>
                      <b-td>{{$store.api.price(c.montant_ht)}}€</b-td>                      
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-tab>
            </b-tabs>
          </form>
        </b-card>
      </div>
    </b-col>
    <b-modal id="uploadDoc" ref="uploadDoc" title="Uploader votre document">
      <b-form-group label="Document:">
        <b-input-group class="mt-3">
          <input type="file" placeholder="Choisissez votre document" @change="handleDoc" class="form-control"/>
        </b-input-group>
      </b-form-group>
    </b-modal>
  </b-row>
</template>
<script>
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
export default {
  name: "",
  components: {
    Treeselect
  },
  watch:{
    'partenaire.type_commission'(){
      if(this.partenaire.type_commission === 1){
        this.partenaire.type_commission_lineaire = null;
      }
    },
    'partenaire.status_id'(){
      this.user.status = this.partenaire.status_id === 1 ? 1 : 0;
    }
  },
  data(){
    return {
      save_loader: false,
      params: false,
      partenaire: false,
      user:{},
      factures:[],
      doc_id:null,
      doc_key:null,
      commissions:[],
      commentaireTmp:null
    }
  },
  methods: {
    init(){
      this.$store.api.ajax('/partenaire/'+this.$route.params.id, null, (res) => {
        if(res.status === true){
          this.user = res.data.user;
          this.partenaire = res.data.partenaire;
          this.factures = res.data.factures
          this.commissions = res.data.commissions;
        }
      });
    },
    addCommentaire(){
      if(this.partenaire.commentaire === null){
        this.partenaire.commentaire = "";
      }      
      var date = new Date();
      var day = date.getDate() < 10 ? "0"+date.getDate() : date.getDate();
      var month = date.getMonth() < 10 ? '0'+(date.getMonth() + 1) : date.getMonth() + 1;
      var year = date.getFullYear();
      var hour = date.getHours() < 10 ? "0"+date.getHours() : date.getHours();
      var minute = date.getMinutes() < 10 ? "0"+date.getMinutes() : date.getMinutes();
      var txt = `${this.commentaireTmp} | par ${this.$store.api.user.prenom} ${this.$store.api.user.nom} le ${day}/${month}/${year} à ${hour}:${minute}`;
      this.partenaire.commentaire += txt;
      this.partenaire.commentaire += "\n";
      this.commentaireTmp = null;
      setTimeout(() => {
        var textarea = document.getElementById('commentaire_input');
        textarea.scrollTop = textarea.scrollHeight;
      },5);
    },
    checkForm(){
      var res = true;
      if(this.$refs.form.reportValidity() === false){
        res = false;
      }
      if(this.partenaire.soumission_manuelle === false && this.partenaire.convention === null){
        res = false;
      }
      return res;
    },
    save(){
      if(this.save_loader === true){
        return false;
      }
      if(this.checkForm() === false){
        this.$toastr.w("Merci de remplir tout les champs");
        return false;
      }
      this.save_loader = true;
      var params = {
        partenaire: this.partenaire,
        user:this.user
      }
      this.$store.api.ajax('/partenaire/create', params, (res) => {
        if(res.status === true){
          this.user = res.data.user;
          this.partenaire = res.data.partenaire;
          this.$toastr.s("Sauvegarder");
          this.$router.push({path:"/admin/partenaire/detail/"+this.partenaire.id});
        }
        this.save_loader = false;
      })
    },
    handleFile(e){
      var files = e.target.files;
      const fileUploaded = files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        var tmp = {
          'file': e.target.result,
          'file_name': fileUploaded.name
        }
        this.$store.api.ajax('/sinistre/doc', tmp, (res) => {
          if(res.status == true){
            this.partenaire.convention = res.data;
          }
        })
      }
      reader.readAsDataURL(fileUploaded);
    },
    handleDoc(e){
      var files = e.target.files;
      const fileUploaded = files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        var tmp = {
          'file': e.target.result,
          'file_name': fileUploaded.name,
          'doc_id':this.doc_id,
          'doc_key':this.doc_key
        }
        this.$store.api.ajax('/partenaire/doc', tmp, (res) => {
          if(res.status == true){
            this.doc_id = null;
            this.doc_key = null;
            this.init();
            this.$refs.uploadDoc.hide();
          }
        })
      }
      reader.readAsDataURL(fileUploaded);
    },
    formatDate(date){
      var tmp = date.split('-');
      if(tmp.length === 3){
        return tmp.reverse().join('/')
      }else{
        return '';
      }
    },
    saveFac(f){
      this.$store.api.ajax('/admin/facture', f, res => {
        if(res.status){
          f = res.data
        }
      })
    },
    activation(){
      var params = {
        model: "Partenaire",
        model_id: this.partenaire.id
      }
      this.$store.api.ajax('/partenaire/activation', params, res => {
        if(res.status === true){
          this.$toastr.s("Sauvegarder");
        }
      })
    }
  },
  computed:{
    ifNew(){
      return this.partenaire.id === null ? true : false;
    },
    statusA(){
      return this.$store.api.getParam(this.params.PartenaireStatus, this.partenaire.status_id);
    },
    total_inscrit(){
      var total = 0;
      this.factures.forEach((item) => {
        total += item.nb_inscrit
      });
      return total
    },
    total_souscriptions(){
      var total = 0;
      this.factures.forEach((item) => {
        total += item.nb_souscription
      });
      return total
    },
    total_ht(){
      var total = 0;
      this.factures.forEach((item) => {
        total += item.montant_ht
      });
      return total.toFixed(2);
    }
  },
  beforeMount() {
    this.$store.api.ajax('/partenaire/params', null, (res) => {
      if(res.status === true){
        this.params = res.data
      }
    });

  },
  mounted() {
    if(this.$route.params.id === undefined){
      this.$store.api.ajax('/partenaire/new', null, (res) => {
        if(res.status === true){
          this.user = res.data.user;
          this.partenaire = res.data.partenaire;
        }
      });
    }else{
      this.init();
    }
  }
}
</script>
<style media="screen">
.btn.dropdown-toggle{
  width: 100%;
  height: 100%;
}
</style>
